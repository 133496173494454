import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.svg";
// import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
// import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = tw(ContainerBase)`bg-gray-400 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider text-gray-700`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a` text-gray-700 border-b-2 border-transparent hocus:text-gray-600 hocus:border-gray-600 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

// const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Guaduales de Cafe B305</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="#">Inicio</Link>
            <Link href="#features">servicios</Link>
            <Link href="#gallery">Galería</Link>
            <Link href="#interest">Sitios de Interes</Link>
            <Link href="#faq">Preguntas</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.booking.com/hotel/co/apartamento-a-3km-del-parque-del-cafe.es.html">
              <img src="https://cf.bstatic.com/static/img/b25logo/favicon/ebc77706da3aae4aee7b05dadf182390f0d26d11.ico" alt="Booking"/>
            </SocialLink>
            <SocialLink href="https://www.airbnb.com.co/rooms/52686175">
              <img src="https://a0.muscache.com/airbnb/static/logotype_favicon-21cc8e6c6a2cca43f061d2dcabdf6e58.ico" alt="AirBnb"/>
            </SocialLink>
          </SocialLinksContainer>
        </Row>
      </Content>
    </Container>
  );
};
