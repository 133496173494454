import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
// import SimpleContactUs from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import {useTranslation} from "react-i18next";
import * as Icons from "@fortawesome/free-solid-svg-icons"
import parque_cafe from "../images/parque_cafe.jpeg"
import panaca from "../images/panaca.jpeg"
import recuca from "../images/recuca.jpeg"
import santa_rosa from "../images/santa_rosa.jpeg"
import cocora from "../images/cocora.png"
import salento from "../images/salento.jpeg"
import museo_oro from "../images/museo_oro.jpeg"
import mariposario from "../images/mariposario.jpeg"
import filandia from "../images/filandia.png"

export default () => {
  const {t} = useTranslation('common');
  
  // Development of main 

  const navLinksItems = [
    {
      url: "#features",
      text: t('navbar.services')
    },
    {
      url: "#gallery",
      text: t('navbar.gallery')
    },
    {
      url: "#interest",
      text: "Sitios de Interes"
    },
    // {
    //   url: "#",
    //   text: t('navbar.delivery')
    // },
    // // {
    //   url: "#",
    //   text: t('navbar.contact')
    // },
    {
      url: "#faq",
      text: 'Preguntas'
    },
  ]
  const title=t('main.title')
  const title_append=t('main.title-append')
  const description=t('main.description')
  const waze_text="Waze"
  const maps_text="Google Maps"
  const waze_url="https://waze.com/ul?ll=4.56524896,-75.7610321"
  const maps_url ="https://www.google.com/maps/dir//4.565775,-75.760806"

  // Feature section 

  const featureCards = [
    {
      icon: Icons.faHandSparkles,
      title: t('features.cleaning.title'),
      description: t('features.cleaning.description')
    },
    { 
      icon: Icons.faWifi, 
      title: t('features.wifi.title'), 
      description: t('features.wifi.description')
    },
    { 
      icon: Icons.faParking, 
      title: t('features.parking.title'),
      description: t('features.parking.description')
    }, 
    { 
      icon: Icons.faLock, 
      title: t('features.lock.title'),
      description: t('features.lock.description') 
    },
    { 
      icon: Icons.faTv, 
      title: t('features.entertaiment.title'),
      description: t('features.entertaiment.description') 
    },
    { 
      icon: Icons.faSwimmer, 
      title: t('features.pool.title'),
      description: t('features.pool.description') 
    },
    { 
      icon: Icons.faHotTub, 
      title: t('features.jacuzzi.title'),
      description: t('features.jacuzzi.description') 
    },
    { 
      icon: Icons.faImage, 
      title: t('features.landscape.title'),
      description: t('features.landscape.description') 
    },
    { 
      icon: Icons.faUtensils, 
      title: t('features.kitchen.title'),
      description: t('features.kitchen.description') 
    }
  ];
  const features_title="Todo lo que necesitas"
  const features_description="Hemos preparado todo en tu estadia en nuestro apartamento para que te sientas en casa, disfrutes de tu estadia y descanses junto con tu familia."

  //image features 
  const main_feature_title = t('main_feature.title')
  const main_feature_description= t('main_feature.description')
  const statistics = [
    {
      key: "Altitud",
      value: "1316 m.s.n.m",
    },
    {
      key: "Temperatura Promedio",
      value: "23 ºC",
    },
    {
      key: "Distancia Parque Nacional del Cafe",
      value: "2 Km",
    },
    {
      key: "Ubicación",
      value: "Montenegro (Quindio)",
    }
  ]

  // Sitios de interes section
  const interest_title="Sitios de Interes"
  const go_now_text= 'Ir Ahora'
  const interest_places = [
    {
      imageSrc: parque_cafe,
      title: "Parque Nacional del Cafe",
      description: "",
      locationText: "Montenegro, Quindio",
      pricingText: "5 mins",
      url: "https://waze.com/ul?ll=4.540972218104029,-75.77189170664467"
    },
    {
      imageSrc: panaca,
      title: "Panaca",
      description: "",
      locationText: "Quimbaya, Quindio",
      pricingText: "34 mins",
      url: "https://waze.com/ul?ll=4.609229045169727,-75.81666052052336"
    },
    {
      imageSrc: recuca,
      title: "Recuca",
      description: "",
      locationText: "Barcelona, Quindio",
      pricingText: "45 mins",
      url: "https://waze.com/ul?ll=4.4324608651249555,-75.73333935592024"
    },
    {
      imageSrc: santa_rosa,
      title: "Termales Santa Rosa de Cabal",
      description: "",
      locationText: "Santa Rosa de Cabal, Risaralda",
      pricingText: "1 h 45 mins",
      url: "https://waze.com/ul?ll=4.842717870980775,-75.55518597908804"
    },
    {
      imageSrc: cocora,
      title: "Valle de Cocora",
      description: "",
      locationText: "Salento, Quindio",
      pricingText: "1 h 20 mins",
      url: "https://waze.com/ul?ll=4.637209392166373,-75.48829752577957"
    },
    {
      imageSrc: salento,
      title: "Salento",
      description: "",
      locationText: "Salento, Quindio",
      pricingText: "1 h",
      url: "https://waze.com/ul?ll=4.636937604250676,-75.56961833769526"
    },
    {
      imageSrc: museo_oro,
      title: "Museo del Oro Quimbaya",
      description: "",
      locationText: "Armenia, Quindio",
      pricingText: "40 mins",
      url: "https://waze.com/ul?ll=4.571196596839004,-75.64772105238717"
    },
    {
      imageSrc: mariposario,
      title: "Jardin Botanico de Quindio",
      description: "",
      locationText: "Calarca, Quindio",
      pricingText: "45 mins",
      url: "https://waze.com/ul?ll=4.5105376292622585,-75.6525195543259"
    },
    {
      imageSrc: filandia,
      title: "Filandia",
      description: "",
      locationText: "Filandia, Quindio",
      pricingText: "50 mins",
      url: "https://waze.com/ul?ll=4.674527944531222,-75.65796402542772"
    },
  ]

  // FAQ Section
  const faq_heading = "Preguntas"
  const faq_description = "Sabemos que puedes tener algunas dudas. Por eso hemos preparado esta sección con algunas respuestas a las preguntas que quizas te hayas hecho."
  const faqs = [
    {
      question: "En que nivel se encuentra el apartamento?",
      answer:
        "El apartamento se encuentra en el tercer nivel, lo que te permite tener una vista increible."
    },
    {
      question: "Cuantas habitaciones tiene el apartamento?",
      answer:
        "El apartamento cuenta con dos habitaciones, una cama doble, una cama semidoble, una cama sencilla y un sofacama."
    },
    {
      question: "El apartamento cuenta con servicio de calentador de agua?",
      answer:
        "Sí, contamos con servicio de agua caliente para baño."
    },
    {
      question: "Con quien me puedo contactar?",
      answer:
        "Puedes contactarnos a nuestro email apto.mntnegro@gmail.com"
    },
    {
      question: "A partir de que hora esta disponible el apartamento",
      answer:
        "El Check-in se hace a partir de las 3 pm y el Check-out hasta las 12 m"
    },
    {
      question: "Viene equipado el apartamento con lenceria",
      answer:
        "Claro que sí, Tenemos todo listo para tu estadía, lo que incluye toallas de baño, toallas de piscina y ropa de cama. No te preocupes, ya lo hemos pensado todo!"
    }
  ];

  //Gallery Section
  const gallery_title = "Galeria"
  const gallery_description = "Nuestra galeria de fotos"

  return (
    <AnimationRevealPage>
      <Hero navItems={navLinksItems} title={title} description={description} title_append={title_append} primaryActionUrl={waze_url} primaryActionText={waze_text} secondaryActionUrl={maps_url} secondaryActionText={maps_text}/>
      <section id="features">
        <Features cards={featureCards} heading={features_title} description={features_description}/>
        <MainFeature title={main_feature_title} description={main_feature_description} statistics={statistics}/>
      </section>
      <section id="interest">
        <SliderCard cards={interest_places} go_button_text={go_now_text} title={interest_title}/>
      </section>
      <section id="gallery">
        <Testimonial heading={gallery_title} description={gallery_description}/>
      </section>
      {/* <TrendingCard /> */}
      <section id="faq">
        <FAQ faqs={faqs} heading={faq_heading} description={faq_description}/>
      </section>
      {/* <SimpleContactUs/> */}
      <Footer />
    </AnimationRevealPage>
  )
};
