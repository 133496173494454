import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

const userLangComplete = navigator.language || navigator.userLanguage;
const userLang = userLangComplete.split('-')[0];

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: userLang,                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      es: {
          common: common_es
      },
  },
});

Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </React.StrictMode>,
  document.getElementById("root")
);
